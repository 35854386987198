
























import Vue from "vue";
import { defineComponent, watch } from "@vue/composition-api";
import Modal from "@monorepo/app/src/components/Modal.vue";
import Snackbar from "@monorepo/app/src/components/Snackbar.vue";
import Navigation from "@monorepo/app/src/components/Navigation.vue";
import { mapActions, mapGetters } from "vuex";
import Header from "@monorepo/app/src/components/Header.vue";
import ServiceTypeNotification from "@monorepo/app/src/components/ServiceTypeNotification.vue";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import useSocket from "@monorepo/monitoring/src/views/EventLogView/composables/useSocket";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { convertFromBinary } from "@monorepo/utils/src/api/convertFromBinary";
import { v4 as uuid } from "uuid";
import eventBus from "@monorepo/utils/src/eventBus";
import TmpBlock from "@monorepo/uikit/src/components/tableViews/TmpBlock.vue";
import TmpLogoOverlay from "@monorepo/uikit/src/components/tableViews/TmpLogoOverlay.vue";
import ProfileModal from "./components/ProfileModal.vue";
import BugButton from "./components/BugButton.vue";
import { CompatClient } from "@stomp/stompjs";
import { debounce } from "lodash";

Vue.component("TmpBlock", TmpBlock);
Vue.component("TmpLogoOverlay", TmpLogoOverlay);

export default defineComponent({
  name: "App",
  components: { Header, BugButton, Navigation, Modal, Snackbar, ServiceTypeNotification, ProfileModal },
  data() {
    return {
      isShowServiceType: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isAuth", "userSettings"]),
    ...mapGetters("app", ["isOpenProfile"]),
    isShowAnimation(): boolean {
      return this.userSettings["ANIMATION"];
    },
    isNeedNavigation(): boolean {
      return !!this.$route.meta.isNeedNavigationPanel;
    },
  },
  methods: {
    ...mapActions("app", ["getMockGeneratorStatus"]),
    toggleShowServiceType(isShowServiceType: boolean) {
      this.isShowServiceType = isShowServiceType;
    },
  },
  setup(props, { root }) {
    let socketItem: WebSocket, stompClientItem: CompatClient;

    const refreshNotificationsDebounce = debounce(function (event) {
      if (event) {
        const data = JSON.parse(convertFromBinary((event as unknown as { _binaryBody: number[] })._binaryBody));
        data.guid = uuid();

        if (root.$store.state.auth.user?.settings?.NOTIFICATION_ENABLED) {
          root.$store.commit("app/setNotifications", data);
        }
        eventBus.$emit("newNotification", data);
      }
    }, 500);

    const refreshSpecificDebounce = debounce(function (event) {
      if (event) {
        const data = JSON.parse(convertFromBinary((event as unknown as { _binaryBody: number[] })._binaryBody));
        data.guid = uuid();

        if (root.$store.state.auth.user?.settings?.NOTIFICATION_ENABLED) {
          root.$store.commit("app/setNotifications", data);
        }
        eventBus.$emit("newNotification", data);
      }
    }, 500);

    watch(
      () => root.$store.getters["auth/isAuth"],
      function (value) {
        if (value) {
          socketItem?.close?.();
          stompClientItem?.deactivate?.();
          const socketPath = `${environmentVariables.NOTIFICATION_SERVICE_PATH}/ws`;
          useSocket(socketPath, root)
            .then(({ stompClient, socket }) => {
              socketItem = socket;
              stompClientItem = stompClient;

              stompClient.subscribe("/topic/events", refreshNotificationsDebounce);
              stompClient.subscribe("/topic/notify", refreshNotificationsDebounce);
              stompClient.subscribe("/user/queue/specific", refreshSpecificDebounce);
            })
            .catch((e) => {
              console.error("Ошибка соединия с сокетом оповещений", e);
              // showNotification("Ошибка соединения с сервисом системных оповещений. Обратитесь к Администратору");
            });
        }
      },
      { immediate: true }
    );
  },
});
